<template>
  <div>
    <div class="remark">
      <div class="title">催款描述</div>
      <div class="inp">{{ title }}</div>
    </div>
    <div class="remark">
      <div style="overflow: hidden">
        <span class="k">日期</span>
        <span class="v">{{ date }}</span>
      </div>
      <div style="overflow: hidden">
        <span class="k">逾期天数</span>
        <span class="v">{{ OverdueDay }}</span>
      </div>
      <div style="overflow: hidden">
        <span class="k">逾期金额</span>
        <span class="v">￥{{ Money }}</span>
      </div>
    </div>
    <div class="remark">
      <div class="p c b">{{ CompanyName }}</div>
      <div class="p">单号：{{ Code }}</div>
      <div class="p">销售日期：{{ SaleDate }}</div>
      <div class="p">客户：{{ CusName }}</div>
      <div class="p">客户地址：{{ Addr }}</div>
      <div class="p">客户电话：{{ Phone }}</div>
      <div class="p">业务员：{{ RealName }}</div>
      <table>
        <tr>
          <th style="width: 5%"></th>
          <th style="width: 20%">品名/编码</th>
          <th style="width: 20%">数量</th>
          <th style="width: 15%">单位</th>
          <th style="width: 20%">单价</th>
          <th style="width: 20%">金额</th>
        </tr>
        <tbody v-for="(item, index) in sub" :key="index">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ item.cInvCode }}</td>
            <td style="text-align: center">{{ item.Quantity }}</td>
            <td style="text-align: center">{{ item.GroupName }}</td>
            <td style="text-align: right">{{ toThousands(item.Price) }}</td>
            <td style="text-align: right">{{ toThousands(item.Money) }}</td>
          </tr>
          <tr>
            <td></td>
            <td colspan="5">{{ item.InventName }}</td>
          </tr>
        </tbody>
        <tr style="border-top: 1px dotted #ccc">
          <td colspan="2">合计</td>
          <td style="text-align: center">{{ TotalQuantity }}</td>
          <td colspan="3" style="text-align: right">{{ TotalSum }}</td>
        </tr>
      </table>
      <div>{{ PaymentType }}：{{ PendingSum }}</div>
    </div>
  </div>
</template>
  
<script>
import wx from "weixin-js-sdk";
import { getMonthRange, toThousands } from "../../utils/Tools";
export default {
  data() {
    return {
      getMonthRange,
      toThousands,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      title: "",
      date: "",
      OverdueDay: "",
      Code: "",
      SaleDate: "",
      CusName: "",
      Addr: "",
      Phone: "",
      RealName: "",
      sub: [],
      TotalQuantity: "",
      TotalSum: "",
      PaymentType: "",
      Money: "",
      PendingSum: "",
      CompanyName: "",
    };
  },
  async mounted() {
    this.title =
      this.$route.query.title == ""
        ? "尊敬的客户，您本月账单已逾期，如已支付请忽略。"
        : this.$route.query.title;
    this.getShareInfo();
    this.getDataList();
  },
  methods: {
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://hzq.yoojet.com/"
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "欠款提醒",
          desc: this.title,
          link:
            location.href.split("#")[0] +
            "#/App/Reconciliation?data=" +
            JSON.stringify({
              CompanyId: this.$route.query.coid,
              Id: this.$route.query.id,
              CustomerId: this.$route.query.cusid,
              days: this.OverdueDay,
              token: this.$route.query.token,
            }),
          imgUrl: "https://demo.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
    getDataList() {
      this.loading = true;

      this.$http
        .post("/BO/BIlling/GetHistoryBIllingOverdueList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: "CreateTime",
          SortType: this.sorter.order,
          Search: {
            Condition: this.$route.query.id,
            Keyword: "",
            CompanyId: this.$route.query.coid,
            CustomerId: this.$route.query.cusid,
            Time: "2022-02-02",
            OverdueDay: "",
            Money: "",
            CompanyName: "",
            PendingSum: "",
          },
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            if (resJson.Data) {
              this.date =
                resJson.Data[0].CreateTime == ""
                  ? ""
                  : resJson.Data[0].CreateTime.substring(0, 10);
              this.OverdueDay = resJson.Data[0].OverdueDay;
              this.Money = toThousands(resJson.Data[0].Money);
              this.CompanyName = resJson.Data[0].maindata.CompanyName;
              this.Code = resJson.Data[0].maindata.SalesOrder;
              this.SaleDate =
                resJson.Data[0].maindata.CreateTime == ""
                  ? ""
                  : resJson.Data[0].maindata.CreateTime.substring(0, 10);
              this.CusName = resJson.Data[0].maindata.cCusName;
              this.Addr = resJson.Data[0].maindata.Address;
              this.Phone = resJson.Data[0].maindata.cCusPhone;
              this.RealName = resJson.Data[0].maindata.RealName;
              this.sub = resJson.Data[0].detalist;
              this.TotalQuantity = resJson.Data[0].maindata.TotalQuantity;
              this.TotalSum = toThousands(resJson.Data[0].maindata.TotalSum);
              this.PaymentType = resJson.Data[0].maindata.PaymentType;
              this.PendingSum = resJson.Data[0].maindata.PendingSum;
            }
            // this.tableData = resJson.Data;
            // if (this.tableData) {
            //     const pagination = { ...this.pagination };
            //     pagination.total = resJson.Total;
            //     this.pagination = pagination;
            // }
          }
        });
    },
  },
};
</script>
  
<style lang="less" scoped>
.remark {
  overflow: hidden;
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px auto;
  width: 90%;
}

.title {
  color: #888;
  font-size: 12px;
}

.inp {
  margin-top: 8px;
  color: #333;
  font-size: 14px;
}

.k {
  float: left;
  font-size: 16px;
  color: 888;
}

.v {
  float: right;
  font-size: 16px;
  color: 333;
  font-weight: 600;
}

.p {
  font-size: 16px;
  color: #333;
  line-height: 26px;
}

.c {
  text-align: center;
}

.b {
  font-weight: 600;
}

table {
  display: block;
  border: none;
  margin-top: 8px;
  width: 100%;
}

th {
  border-bottom: 1px dotted #ccc;
  text-align: center;
}
</style>
    